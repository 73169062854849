import accountApi from '@/api/account'
import * as types from '../mutation-types'

export default {
  getUser: async ({ state, commit }, token) => {
    const key = token
    if (state.cachedUser[key]) {
      const user = state.cachedUser[key]
      commit(types.SET_USER, { user })

      return Promise.resolve(state.cachedUser[key])
    }
    const response = await accountApi.getUser()
    commit(types.CACHE_USER, {
      key,
      user: response.data,
    })
    commit(types.SET_USER, { user: response.data })

    return response.data
  },
}
