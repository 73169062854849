<template>
  <main
    class="app-main"
  >
    <div
      key="main-content"
      class="app-main__content"
    >
      <SMenu v-if="$route.meta.topBarVisible" />

      <div
        v-if="isLoading"
        key="main-loading"
        class="app-main__loading"
      >
        <Spinner />
      </div>
      <slot />
    </div>

    <WelcomeModal
      v-if="isOnboardingEnabled && !isLoading && onboarding.welcomeActive"
      @close="hideWelcomeModal()"
      @start="startTour()"
    />

    <HelpBadge
      v-if="isOnboardingEnabled && ($mq === 'large' && isHomeScreen)"
      @open="showTourModal()"
    />

    <TourModal
      v-if="isOnboardingEnabled && onboarding.helpActive"
      @skip="skipTour()"
      @start="startTour(true)"
    />

    <TourCompletedModal
      v-if="isOnboardingEnabled && onboarding.tourCompleted"
      @close="hideTourFinishedModal()"
    />
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { SMenu } from '@sas-te/main-menu-vue'

import HelpBadge from '@/components/HelpBadge'

const TourModal = () => import('@/components/TourModal')
const WelcomeModal = () => import('@/components/WelcomeModal')
const TourCompletedModal = () => import('@/components/TourCompletedModal')

export default {
  name: 'AppMain',

  components: {
    SMenu,
    WelcomeModal,
    TourCompletedModal,
    TourModal,
    HelpBadge,
  },

  computed: {
    ...mapGetters(['isLoading', 'onboarding']),

    isHomeScreen() {
      return this.$route.name === 'assessments'
    },

    isExamScreen() {
      return this.$route.name === 'exam'
    },

    isOnboardingEnabled() {
      return JSON.parse(process.env.VUE_APP_ONBOARDING_ENABLED)
    },
  },

  watch: {
    isHomeScreen(newValue) {
      if (!newValue) {
        this.setOnboarding({
          ...this.onboarding,
          tourCompleted: false,
        })
      }
    },
  },

  created() {
    this.initOnboarding()
  },

  methods: {
    ...mapActions(['setOnboarding']),

    initOnboarding() {
      const onboarding = JSON.parse(localStorage.getItem('onboarding'))
      if (onboarding) {
        if (onboarding.tourActive) {
          onboarding.tourActive = false
          onboarding.helpActive = true
          this.setOnboarding(onboarding)
        }

        return
      }
      this.setDefaultOnboarding()
    },

    setDefaultOnboarding() {
      const onboarding = {
        welcomeActive: true,
        tourActive: false,
        tourCompleted: false,
        helpActive: false,
        helpTourButtonPressed: false,
      }
      localStorage.setItem('onboarding', JSON.stringify(onboarding))
    },

    hideWelcomeModal() {
      this.setOnboarding({
        ...this.onboarding,
        welcomeActive: false,
        helpActive: false,
      })
    },

    showTourModal() {
      this.setOnboarding({
        ...this.onboarding,
        helpActive: true,
      })
    },

    startTour(isFromTourModal) {
      this.$router.push({ name: 'home' })
      this.setOnboarding({
        ...this.onboarding,
        welcomeActive: false,
        tourActive: true,
        helpActive: false,
        helpTourButtonPressed: !!isFromTourModal,
      })
    },

    skipTour() {
      this.setOnboarding({
        ...this.onboarding,
        tourActive: false,
        helpActive: false,
      })
    },

    hideTourFinishedModal() {
      this.setOnboarding({
        ...this.onboarding,
        tourCompleted: false,
      })
    },
  },
}
</script>

<style lang="sass">
.app-main

  &__loading
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: calc(100vh - 56px)
</style>
