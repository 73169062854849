<template>
  <div id="app">
    <AppMain>
      <router-view />
    </AppMain>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import AppMain from '@/components/AppMain'

export default {
  name: 'App',
  components: {
    AppMain,
  },
  created() {
    this.addUserToDataLayer()
  },
  methods: {
    ...mapActions(['getUser']),
    async addUserToDataLayer() {
      const user = await this.getUser()

      window.dataLayer.push({
        accountId: user.id,
        schoolId: user.school.id,
        profileId: user.profiles[0].id,
        gradeId: user.grades.map((grade) => grade.id),
        prospection: this.$store.state.profile.personalData?.schoolProspection,
      })
    },
  },
}
</script>

<style lang="sass">
@import '~@/styles/main'

body
  background: $color-ice
  overflow-x: hidden

  &.lock-scroll
    overflow: hidden

  #app
    padding-top: $size-xxl
</style>
