import axios from 'axios'
import { stringify } from 'qs'

import {
  updateTokens,
  hasRefreshToken,
  getAuthToken,
  redirectToLogin,
  removeAuthCookies,
} from '@/utils/auth'
import httpCodes from '@/enums/httpCodes'
import urls from '@/utils/urls'

axios.defaults.baseURL = urls.LOTUS_API

export const addAuthorizationHeader = (config) => {
  const requestConfig = { ...config }

  requestConfig.headers.Authorization = getAuthToken()

  return requestConfig
}

const api = axios.create({
  baseURL: urls.LOTUS_API,
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'comma' }),
})

api.interceptors.request.use(addAuthorizationHeader)

api.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line promise/prefer-await-to-callbacks
  async (error) => {
    async function retryWithRefreshToken() {
      try {
        await updateTokens()

        return axios(addAuthorizationHeader(error.config))
      } catch {
        return Promise.reject(error)
      }
    }

    const handleRequestError = () => {
      const errorsPages = [httpCodes.serviceUnavailable, httpCodes.forbidden]

      const { status } = error.response

      if (status === httpCodes.unauthorized) {
        redirectToLogin()
      } else if (errorsPages.includes(status)) {
        window.location.assign(`${urls.PORTAL_URL}/error/${status}`)
      }
    }

    if (error.response.status === httpCodes.unauthorized && hasRefreshToken()) {
      try {
        return await retryWithRefreshToken()
      } catch {
        removeAuthCookies()
        handleRequestError()
      }
    } else {
      handleRequestError()
    }

    return Promise.reject(error)
  },
)

export default api
