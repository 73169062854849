import resultApi from '@/api/result'
import * as types from '../mutation-types'

const getItemGlobal = (
  globalPerformancesItems,
  itemId,
) => globalPerformancesItems.find(
  (globalPerformance) => globalPerformance.itemId === itemId,
)

export default {
  getResults: async ({ commit, dispatch }, application) => {
    const appointments = application.appointments.filter(
      (appointment) => appointment.status === 'DONE',
    )

    if (appointments.length === 0) {
      return
    }

    let examId = 0
    const responsePromises = appointments.map((appointment) => {
      examId = appointment.examId

      return (
        dispatch('getApplicationResponse', {
          applicationId: application.id,
          examId: appointment.examId,
        }))
    })
    const applicationResponses = await Promise.all(responsePromises)
    const resultPromises = applicationResponses.map(
      (response) => {
        console.log(examId, response.id)

        return (
          resultApi.getResult(examId, response.id))
      },
    )
    const resultResponses = await Promise.all(resultPromises)

    const results = resultResponses.map((response) => response.data)
    const examResults = appointments.map((appointment, index) => ({
      examId: appointment.examId,
      ...results[index],
    }))
    commit(types.SET_PARTIAL_RESULTS, { partialResults: examResults })
  },

  getScores: (context, examId) => resultApi
    .getScores(examId)
    .then((response) => response.data),

  getResult: (context, { examId, applicationResponseId }) => resultApi
    .getResult({
      examId,
      applicationResponseId,
    })
    .then((response) => response.data),

  getScoresByAssessment: (context, assessmentId) => resultApi
    .getAreasScoresByAssessment(assessmentId)
    .then((response) => response.data),

  getGlobalScores: (context, examId) => resultApi
    .getGlobalScores(examId)
    .then((response) => response.data),

  getGlobalScoresByAssessment: (context, assessmentId) => resultApi
    .getAreasGlobalScoresByAssessment(assessmentId)
    .then((response) => response.data),

  getScoreResult: async (
    { state, commit, dispatch },
    { examId, force = false },
  ) => {
    const key = `${examId}`
    const cachedScoreResult = state.cachedScoreResult[key]
    if (!force && cachedScoreResult) {
      return Promise.resolve(cachedScoreResult)
    }

    const scorePromise = dispatch('getScores', examId)
    const globalScorePromise = dispatch('getGlobalScores', examId)
    const [scores, globalScores] = await Promise.all([
      scorePromise,
      globalScorePromise,
    ])

    const scoreResult = {
      examId: parseInt(examId, 10),
      scores,
      globalScores,
    }
    commit(types.CACHE_SCORE_RESULT, {
      key,
      scoreResult,
    })

    return Promise.resolve(scoreResult)
  },

  getScoreResultByAssessment: async (
    { state, commit, dispatch },
    { assessmentId, force },
  ) => {
    const key = `${assessmentId}`
    const cachedScoreResult = state.cachedScoreResultByAssessment[key]
    if (!force && cachedScoreResult) {
      return Promise.resolve(cachedScoreResult)
    }
    const scorePromise = dispatch('getScoresByAssessment', assessmentId)
    const globalScorePromise = dispatch(
      'getGlobalScoresByAssessment',
      assessmentId,
    )
    const [scores, globalScores] = await Promise.all([
      scorePromise,
      globalScorePromise,
    ])
    const scoreResult = {
      assessmentId: parseInt(assessmentId, 10),
      scores,
      globalScores,
    }
    commit(types.CACHE_SCORE_RESULT_BY_ASSESSMENT, {
      key,
      scoreResult,
    })

    return Promise.resolve(scoreResult)
  },

  getAreasScores: (context, examId) => resultApi
    .getAreasScores(examId)
    .then((response) => response.data),

  getAreasGlobalScores: (context, examId) => resultApi
    .getAreasGlobalScores(examId)
    .then((response) => response.data),

  getAreasScoreResult: async (
    { state, commit, dispatch },
    { examId, force = false },
  ) => {
    const key = `${examId}`
    const cachedAreasScoreResult = state.cachedAreasScoreResult[key]
    if (!force && cachedAreasScoreResult) {
      return Promise.resolve(cachedAreasScoreResult)
    }

    const scorePromise = dispatch('getAreasScores', examId)
    const globalScorePromise = dispatch('getAreasGlobalScores', examId)
    const [scores, globalScores] = await Promise.all([
      scorePromise,
      globalScorePromise,
    ])

    const areasScoreResult = {
      examId: parseInt(examId, 10),
      scores,
      globalScores,
    }
    commit(types.CACHE_AREAS_SCORE_RESULT, {
      key,
      areasScoreResult,
    })

    return Promise.resolve(areasScoreResult)
  },

  getPartialResultHits: ({ state, commit }, applicationResponseId) => {
    const key = `${applicationResponseId}`
    const cachedPartialResultHits = state.cachedPartialResultHits[key]
    if (cachedPartialResultHits) {
      return Promise.resolve(cachedPartialResultHits)
    }

    return resultApi
      .getPartialResults(applicationResponseId)
      .then((response) => {
        const resultHits = response.data
        commit(types.CACHE_PARTIAL_RESULT_HITS, {
          key,
          resultHits,
        })

        return resultHits
      })
  },

  getHitsResult: ({ state, commit }, { assessmentId, examId }) => {
    const key = `${assessmentId}__${examId}`
    const cachedHitsResult = state.cachedHitsResult[key]
    if (cachedHitsResult) {
      return Promise.resolve(cachedHitsResult)
    }

    return resultApi
      .getHitsResult(assessmentId, examId)
      .then((response) => {
        const resultHits = response.data
        commit(types.CACHE_HITS_RESULT, {
          key,
          resultHits,
        })

        return resultHits
      })
  },

  getPerformanceByCategories: async (
    { state, commit },
    { assessmentId, examId, force = false },
  ) => {
    const key = `${assessmentId}__${examId}`
    const cachedPerformanceCategories = state.cachedPerformanceCategories[key]
    if (!force && cachedPerformanceCategories) {
      return cachedPerformanceCategories
    }
    const response = await resultApi.getPerformanceByCategories(
      assessmentId,
      examId,
    )
    const performanceCategories = response.data
    commit(types.CACHE_PERFORMANCE_CATEGORIES, {
      key,
      performanceCategories,
    })

    return performanceCategories
  },

  getGlobalPerformanceByCategories: async (
    { state, commit },
    { examId, force = false },
  ) => {
    const key = `${examId}`
    const cachedGlobalPerformance = state.cachedGlobalPerformance[key]
    if (!force && cachedGlobalPerformance) {
      return cachedGlobalPerformance
    }

    const response = await resultApi.getGlobalPerformanceByCategories(examId)
    const globalPerformance = response.data
    commit(types.CACHE_CATEGORIES_GLOBAL_PERFORMANCE, {
      key,
      globalPerformance,
    })

    return globalPerformance
  },

  getPartialItemsPerformance: ({ state, commit }, applicationResponseId) => {
    const key = `${applicationResponseId}`
    const cachedItemsPerformance = state.cachedPartialItemsPerformance[key]
    if (cachedItemsPerformance) {
      return Promise.resolve(cachedItemsPerformance)
    }

    return resultApi
      .getPartialItemsPerformance(applicationResponseId)
      .then((response) => {
        const itemsPerformance = response.data
        commit(types.CACHE_PARTIAL_ITEMS_PERFORMANCE, {
          key,
          itemsPerformance,
        })

        return itemsPerformance
      })
  },

  getPerformanceByItems: (context, examId) => resultApi
    .getPerfomanceByItems(examId)
    .then((response) => response.data),

  getPerformanceItemsByAssessments: (context, assessmentId) => resultApi
    .getPerformanceItemsByAssessments(assessmentId)
    .then((response) => response.data),

  getGlobalPerformanceByItems: (context, examId) => resultApi
    .getGlobalPerfomanceByItems(examId)
    .then((response) => response.data),

  getGlobalPerformanceItemsByAssessments: (context, assessmentId) => resultApi
    .getGlobalPerformanceItemsByAssessments(assessmentId)
    .then((response) => response.data),

  getPerformanceByItemsResult: async (
    { state, commit, dispatch },
    { assessmentId, examId, slug },
  ) => {
    const key = `${assessmentId}__${examId}`
    const cachedItemsPerformance = state.cachedItemsPerformance[key]
    if (cachedItemsPerformance) {
      return Promise.resolve(cachedItemsPerformance)
    }
    const performancesPromise = slug.startsWith('enem')
      ? dispatch('getPerformanceItemsByAssessments', assessmentId)
      : dispatch('getPerformanceByItems', examId)

    const globalPerformancesPromise = slug.startsWith('enem')
      ? dispatch('getGlobalPerformanceItemsByAssessments', assessmentId)
      : dispatch('getGlobalPerformanceByItems', examId)

    const [performances, globalPerformances] = await Promise.all([
      performancesPromise,
      globalPerformancesPromise,
    ])

    const itemsPerformance = performances.map((performance) => {
      const globalItem = getItemGlobal(globalPerformances, performance.itemId)

      return {
        globalGrade: globalItem.grade,
        examType: globalItem.examType,
        lecture: globalItem.lecture,
        ...performance,
      }
    })
    commit(types.CACHE_PERFORMANCE_ITEMS, {
      key,
      itemsPerformance,
    })

    return itemsPerformance
  },

  getGeneralRankings: async ({ state, commit }, { examId, force = false }) => {
    const key = `${examId}`
    const cachedGeneralRankings = state.cachedGeneralRankings[key]
    if (!force && cachedGeneralRankings) {
      return cachedGeneralRankings
    }

    const response = await resultApi.getRankings(examId)
    const generalRankings = response.data
    commit(types.CACHE_GENERAL_RANKINGS, {
      key,
      generalRankings,
    })

    return generalRankings
  },

  getParticipation: async ({ state, commit }, { examId, force = false }) => {
    const key = `${examId}`
    const cachedParticipation = state.cachedParticipation[key]
    if (!force && cachedParticipation) {
      return cachedParticipation
    }

    const response = await resultApi.getParticipation(examId)
    const participation = response.data
    commit(types.CACHE_PARTICIPATION, {
      key,
      participation,
    })

    return participation
  },

  getCategoriesRanking: async ({ state, commit }, { examId, force }) => {
    const key = `${examId}`
    const cachedCategoriesRanking = state.cachedCategoriesRanking[key]
    if (!force && cachedCategoriesRanking) {
      return cachedCategoriesRanking
    }

    const response = await resultApi.getCategoriesRanking(examId)
    const categoriesRanking = response.data
    commit(types.CACHE_CATEGORIES_RANKING, {
      key,
      categoriesRanking,
    })

    return categoriesRanking
  },

  getHistogram: async ({ state, commit }, { examId, isHighSchool, force }) => {
    const key = `${examId}`
    const cachedHistogram = state.cachedHistogram[key]
    if (!force && cachedHistogram) {
      return cachedHistogram
    }

    const { data } = isHighSchool
      ? await resultApi.getIrtHistogram(examId)
      : await resultApi.getClassicHistogram(examId)
    const histogram = data
    commit(types.CACHE_HISTOGRAM, {
      key,
      histogram,
    })

    return histogram
  },

  getExamTypeHistogram: async (_, { assessmentId }) => {
    const { data } = await resultApi.getExamTypeHistogram(assessmentId)

    return data
  },

  getIrtHistogramByAssessment: async (
    { state, commit },
    { assessmentId, force },
  ) => {
    const key = `${assessmentId}`
    const cachedHistogram = state.cachedHistogramByAssessment[key]
    if (!force && cachedHistogram) {
      return cachedHistogram
    }

    const { data } = await resultApi.getIrtHistogramByAssessment(assessmentId)
    const histogram = data
    commit(types.CACHE_HISTOGRAM_BY_ASSESSMENT, {
      key,
      histogram,
    })

    return histogram
  },

  getStandardHistogram: async ({ state, commit }, { examId, force }) => {
    const key = `${examId}`
    const cachedHistogram = state.cachedHistogram[key]
    if (!force && cachedHistogram) {
      return cachedHistogram
    }

    const { data } = await resultApi.getStandardHistogram(examId)
    const histogram = data
    commit(types.CACHE_HISTOGRAM, {
      key,
      histogram,
    })

    return histogram
  },

  getCategoriesHistogram: async ({ state, commit }, { examId, force }) => {
    const key = `${examId}`
    const cachedCategoriesHistogram = state.cachedCategoriesHistogram[key]
    if (!force && cachedCategoriesHistogram) {
      return cachedCategoriesHistogram
    }

    const { data } = await resultApi.getCategoriesHistogram(examId)
    const categoriesHistogram = data
    commit(types.CACHE_CATEGORIES_HISTOGRAM, {
      key,
      categoriesHistogram,
    })

    return categoriesHistogram
  },

  getGeneralRankingsByAssessment: async (
    { state, commit },
    { assessmentId, force },
  ) => {
    const key = `${assessmentId}`
    const cachedGeneralRankings = state.cachedGeneralRankingsByAssessment[key]
    if (!force && cachedGeneralRankings) {
      return cachedGeneralRankings
    }

    const response = await resultApi.getRankingsByAssessment(assessmentId)
    const generalRankings = response.data
    commit(types.CACHE_GENERAL_RANKINGS_BY_ASSESSMENT, {
      key,
      generalRankings,
    })

    return generalRankings
  },

  getParticipationByAssessment: async (
    { state, commit },
    { assessmentId, force },
  ) => {
    const key = `${assessmentId}`
    const cachedParticipation = state.cachedParticipationByAssessment[key]
    if (!force && cachedParticipation) {
      return cachedParticipation
    }
    const [participation, globalParticipation] = await Promise.all([
      resultApi.getParticipationByAssessment(assessmentId),
      resultApi.getGlobalParticipationByAssessment(assessmentId),
    ])
    const data = {
      participation: participation.data,
      globalParticipation: globalParticipation.data,
    }

    commit(types.CACHE_PARTICIPATION_BY_ASSESSMENT, {
      key,
      data,
    })

    return data
  },

  getAveragesPerformance: async (
    { state, commit },
    { assessmentId, force = false },
  ) => {
    const key = `${assessmentId}`
    const cachedAveragesPerformance = state.cachedAveragesPerformance[key]

    if (!force && cachedAveragesPerformance) {
      return cachedAveragesPerformance
    }

    const {
      data: averagesPerformance,
    } = await resultApi.getAveragesPerfomance({ assessmentId })

    commit(types.CACHE_AVERAGES_PERFORMANCE, {
      key,
      averagesPerformance,
    })

    return averagesPerformance
  },

  getAveragesExamPerformance: async (
    { state, commit },
    { examId, force = false },
  ) => {
    const key = examId
    const cachedAveragesPerformance = state.cachedAveragesPerformance[key]

    if (!force && cachedAveragesPerformance) {
      return cachedAveragesPerformance
    }

    const {
      data: averagesPerformance,
    } = await resultApi.getAveragesExamPerfomance({ examId })

    commit(types.CACHE_AVERAGES_PERFORMANCE, {
      key,
      averagesPerformance,
    })

    return averagesPerformance
  },

  getHitsQuestions: async (
    { state, commit },
    { assessmentId, force = false },
  ) => {
    const key = `${assessmentId}`
    const cachedHitsQuestions = state.cachedHitsQuestions[key]
    if (!force && cachedHitsQuestions) {
      return cachedHitsQuestions
    }

    const { data: hitsQuestions } = await resultApi.getHitsQuestions({
      assessmentId,
    })
    commit(types.CACHE_HITS_QUESTIONS, {
      key,
      hitsQuestions,
    })

    return hitsQuestions
  },

  getOlympicSummary: async ({ commit }, payload) => {
    commit(types.SET_LOADING_OLYMPIC_SUMMARY, true)

    const { data } = await resultApi.getOlympicSummary(payload)
    commit(types.SET_OLYMPIC_SUMMARY_EXAMS, data)
    commit(types.SET_LOADING_OLYMPIC_SUMMARY, false)
  },
}
